<template>
  <svg
    width="82"
    height="34"
    viewBox="0 0 82 34"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5403 33.29C23.4136 33.29 23.3059 33.1824 23.3059 33.0557V14.8873C23.3059 13.6076 23.3059 13.5253 20.4996 12.518C20.2905 12.442 20.2905 12.1443 20.4996 12.0746L26.9611 9.85104C27.1132 9.80036 27.2715 9.91439 27.2715 10.0728V33.0557C27.2715 33.1824 27.1638 33.29 27.0371 33.29H23.5403V33.29Z"
      fill="currentColor"
    />
    <path
      d="M33.7205 33.2908C33.5938 33.2908 33.4861 33.1831 33.4861 33.0564V14.9323C33.4861 13.7351 33.4861 13.6654 30.6227 12.5188C30.42 12.4364 30.42 12.1513 30.6227 12.0753L36.8436 9.85178C36.9956 9.79477 37.1603 9.90879 37.1603 10.0735V14.812C37.1603 14.945 37.344 14.983 37.401 14.8627C39.1495 11.1568 40.7839 9.97848 43.7169 9.97848C44.6165 9.97848 45.326 10.1368 45.8011 10.3396C45.8834 10.3776 45.9341 10.4599 45.9341 10.555V13.4246C45.9341 13.583 45.7821 13.697 45.63 13.6464C44.8889 13.412 44.2934 13.374 43.3368 13.374C41.1196 13.374 38.8517 14.6346 37.4391 17.0925V33.0564C37.4391 33.1831 37.3314 33.2908 37.2047 33.2908H33.7078H33.7205Z"
      fill="currentColor"
    />
    <path
      d="M19.9802 1.85039C19.8091 0.957179 19.0109 0.304688 18.086 0.304688H14.6399C14.0887 0.304688 13.5313 0.304688 12.9801 0.311022C11.3711 0.317357 9.71769 0.260343 8.1213 0.558082H8.09596C7.63985 0.64677 7.18374 0.760798 6.73396 0.89383C5.66337 1.21057 4.63712 1.66668 3.7059 2.2875C2.86969 2.84497 2.12218 3.53547 1.5267 4.34C0.931225 5.14453 0.525794 5.97439 0.272399 6.89295C0.095023 7.57078 0 8.78707 0 9.49658V33.0559C0 33.1826 0.107693 33.2903 0.23439 33.2903H4.15567C4.28237 33.2903 4.39006 33.1826 4.39006 33.0559V23.6803V16.7057C4.39006 16.5726 4.49776 16.4713 4.62445 16.4713C6.41089 16.4966 8.48239 16.5663 9.86339 16.7183C12.3023 17.0034 13.9557 17.5419 15.3177 18.9609C15.4634 19.1129 15.7231 19.0115 15.7231 18.7962V12.0875C15.7231 11.8785 15.4634 11.7708 15.3177 11.9292C13.962 13.3545 12.3086 13.9373 9.86339 14.1717C8.46972 14.3237 6.41722 14.3934 4.62445 14.4188C4.49142 14.4188 4.39006 14.3174 4.39006 14.1844C4.3964 12.6133 4.40273 11.036 4.4154 9.4649C4.4154 8.76173 4.45341 8.09024 4.5421 7.3744C4.60545 6.86127 4.78282 6.36082 4.99188 5.8857C5.37197 5.00516 5.96111 4.2323 6.72129 3.64316C7.51315 3.02235 8.45705 2.62958 9.43895 2.40153C10.2055 2.22415 10.9973 2.17347 11.7829 2.16714C12.5684 2.1608 13.3476 2.14813 14.1077 2.30017C15.0326 2.48388 15.9069 2.87031 16.6797 3.40244C17.6806 4.0866 18.5105 4.99882 19.1883 6.00607C19.3213 6.20245 19.448 6.40516 19.5747 6.60788C19.7014 6.8106 20.0055 6.72191 20.0055 6.48752V2.13546C20.0055 2.04044 20.0055 1.94542 19.9865 1.85039H19.9802Z"
      fill="currentColor"
    />
    <path
      d="M80.7572 29.6227C79.56 30.4082 78.0269 30.839 76.3165 30.839C73.4848 30.839 71.5527 29.3756 71.5527 25.9801V12.7403C71.5527 12.6136 71.6604 12.5059 71.7871 12.5059H79.1229C79.2495 12.5059 79.3572 12.4045 79.3572 12.2715V10.6561C79.3699 10.5231 79.2622 10.4217 79.1292 10.4217L71.7871 10.4597C71.654 10.4597 71.5527 10.3583 71.5527 10.2253V4.15651C71.5527 3.9918 71.3943 3.87777 71.2423 3.93479L64.73 6.15833C64.521 6.22801 64.521 6.53208 64.73 6.60177C67.5617 7.60901 67.5617 7.69136 67.5617 8.97101L67.549 27.5829C67.549 30.8833 69.2975 34.0001 73.9663 34.0001C76.8043 34.0001 79.636 32.4797 81.112 29.9204C81.2387 29.705 80.979 29.4833 80.7699 29.6163L80.7572 29.6227Z"
      fill="currentColor"
    />
    <path
      d="M48.2859 31.2378L47.4877 26.7718C47.4433 26.531 47.7538 26.398 47.9058 26.5881C51.5357 31.3582 54.1076 32.2768 56.6606 32.2768C58.7384 32.2768 60.5312 31.0478 60.5312 28.6405C60.5312 26.5627 59.8217 25.6442 57.7945 24.7446L52.7456 22.5717C49.8696 21.3428 48.3112 19.1763 48.3112 16.4396C48.3112 12.5246 51.523 9.7373 56.1475 9.7373C57.9846 9.7373 59.9991 10.1111 61.6525 10.9029C62.3176 11.2197 62.7484 11.8912 62.7484 12.6323V15.9011C62.7484 16.1355 62.4443 16.2242 62.3176 16.0278C60.2271 12.759 58.2443 11.4224 55.4633 11.4224C52.771 11.4224 51.4977 12.8857 51.4977 14.9129C51.4977 16.4713 52.0361 17.5799 54.5827 18.6125L59.0678 20.5953C61.9945 21.8686 63.7873 23.712 63.7873 27.2469C63.7873 30.8324 60.8163 33.9935 56.0018 33.9935C53.4678 33.9935 51.1492 33.5374 49.2868 32.5872C48.761 32.3211 48.3999 31.8143 48.2922 31.2315L48.2859 31.2378Z"
      fill="currentColor"
    />
  </svg>
</template>
